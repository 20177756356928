import { IACoverageType } from "./IACoverageType";

export const gapCO: IACoverageType = {
  abbr: "CO",
  i18nKey: "common:gp_coverage_type_co",
};

export const IAGapCoverageTypes: IACoverageType[] = [gapCO];

export function getCoverageByAbbr(abbr: string): IACoverageType | undefined {
  return IAGapCoverageTypes.find((coverage) => coverage.abbr === abbr);
}
