import { Province } from "@common/types/Province";
import useEligibleProvinces, { provinceFilters } from "./useEligibleProvinces";

const useIsBuyerProvinceActiveForAftermarket = (
  source: string | null | undefined,
  currentProvince: Province | null | undefined
) => {
  const { provinces, isProvincesLoading } = useEligibleProvinces(
    source || undefined,
    provinceFilters.isAftermarketEnabled
  );

  const isProvinceActive =
    provinces.find((p) => p.abbr === currentProvince?.abbr) !== undefined;

  return {
    isProvinceActive,
    isProvincesLoading,
  };
};

export default useIsBuyerProvinceActiveForAftermarket;
