/**
 * Create a new object and removes null, undefined, and empty string properties from it
 *
 * @param {Record<string, unknown>} obj
 * @returns New object without null, undefined, and empty string properties. If all properties are null, undefined, or empty string, returns an empty object `{}`
 */
const mapValidProperties = (obj: Record<string, unknown>) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (
      newObj[key] === null ||
      newObj[key] === undefined ||
      newObj[key] === ""
    ) {
      delete newObj[key];
    }
  });
  return newObj;
};

const objectUtils = {
  mapValidProperties,
};

export default objectUtils;
