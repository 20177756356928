interface Params {
  vin?: string | null;
  year?: string | null;
  make?: string | null;
  model?: string | null;
  odometer?: number | null;
}

export default function validateRequiredRatesCallFields(
  params: Params
): boolean {
  const hasVin = Boolean(params.vin);
  const hasYmm =
    Boolean(params.year) && Boolean(params.make) && Boolean(params.model);
  const hasMileage = params.odometer != null;

  return (hasVin || hasYmm) && hasMileage;
}
