import {
  basicCoverage,
  prefferedCoverage,
  classicCoverage,
  superiorCoverage,
  limitedPowertrain,
  superiorWrapCoverage,
  oneThousandTwoHundredFifty,
  twoThousandFiveHundred,
  fiveThousand,
  sevenThousandFiveHundred,
  tenThousand,
} from "@modules/purchase/types/ExtendedWarrantyCoverageType";
import { gapCO } from "./GapCoverageType";
import { IACoverageType } from "./IACoverageType";

export type IAProgram = {
  id: string;
  name: string;
  coverageTypes: IACoverageType[];
};

export const ExtendedWarrantyPremiumRightRideDigital: IAProgram = {
  id: "AU190",
  name: "Premium",
  coverageTypes: [
    basicCoverage,
    prefferedCoverage,
    classicCoverage,
    superiorCoverage,
    superiorWrapCoverage,
  ],
};

export const ExtendedWarrantyEssentialHMRightRideDigital: IAProgram = {
  id: "AU193",
  name: "Essential HM",
  coverageTypes: [limitedPowertrain],
};

export const ExtendedWarrantyEssentialVARightRideDigital: IAProgram = {
  id: "AU194",
  name: "Essential VA",
  coverageTypes: [limitedPowertrain],
};

export const ExtendedWarrantyAmaRightRideDigital: IAProgram = {
  id: "AU192",
  name: "AMA",
  coverageTypes: [
    oneThousandTwoHundredFifty,
    twoThousandFiveHundred,
    fiveThousand,
    sevenThousandFiveHundred,
    tenThousand,
  ],
};

export const GapRightRideDigital: IAProgram = {
  id: "GP005",
  name: "GAP Plus",
  coverageTypes: [gapCO],
};

export const IAGapPrograms: IAProgram[] = [GapRightRideDigital];

export const IAExtendedWarrantyPrograms: IAProgram[] = [
  ExtendedWarrantyPremiumRightRideDigital,
  ExtendedWarrantyEssentialHMRightRideDigital,
  ExtendedWarrantyEssentialVARightRideDigital,
  ExtendedWarrantyAmaRightRideDigital,
];
