export type AftermarketSource = {
  id: number;
  name: string;
  isDigital?: boolean;
};

export const RightRideSource: AftermarketSource = {
  id: 1,
  name: "RightRide",
  isDigital: false,
};

export const KijijiSource: AftermarketSource = {
  id: 2,
  name: "Kijiji",
  isDigital: true,
};

export const RightRideDigitalSource: AftermarketSource = {
  id: 3,
  name: "RightRideDigital",
  isDigital: true,
};

export const AftermarketSources: AftermarketSource[] = [
  RightRideSource,
  KijijiSource,
  RightRideDigitalSource,
];
