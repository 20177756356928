import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Tooltip,
  Legend,
  ChartArea,
  ChartData,
  Filler,
  ChartOptions,
  Tick,
  Plugin,
  defaults,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartAnnotation, { AnnotationOptions } from "chartjs-plugin-annotation";
import { ExtendedWarrantyDefaultRatesRemainingWarranty } from "@modules/purchase/types/ExtendedWarrantyRates";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import DotIcon from "@public/images/dot-icon.svg";

const defaultFont = {
  ...defaults.font,
  family: "Volte",
  size: 14,
  weight: "600",
};

defaults.font = {
  ...defaultFont,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Tooltip,
  Legend,
  Filler,
  ChartAnnotation
);

const labels = Array.from({ length: 11 }, (_, i) => i);
const minX = 1;
const maxX = 9;
const quadraticY = (x: number) => -0.04 * x * x + x * 0.85;

const colors = {
  blue: "#00CFEB",
  orange: "#FCB180",
  red: "#F05983",
};

const data: ChartData<"line"> = {
  labels,
  datasets: [
    {
      fill: true,
      pointRadius: 0,
      pointHitRadius: 0,
      data: labels.map((l) => quadraticY(l)),
    },
    {
      fill: true,
      pointRadius: 0,
      pointHitRadius: 0,
      data: labels.map((l) => quadraticY(l)),
    },
  ],
};

const lineAnnotation: AnnotationOptions<"line"> = {
  type: "line",
  drawTime: "afterDatasetsDraw",
  borderColor: "transparent",
  borderWidth: 3,
  arrowHeads: {
    end: {
      display: true,
      fill: false,
      borderColor: "black",
    },
  },
};

const labelAnnotation: AnnotationOptions = {
  type: "label",
  drawTime: "afterDraw",
  content: "",
  borderWidth: 0,
};

const lineGradient = (
  vehicleWarranty: ExtendedWarrantyDefaultRatesRemainingWarranty
): Plugin => {
  const { vehicleAge } = vehicleWarranty;

  let year = vehicleAge < minX ? minX : vehicleAge;
  year = vehicleAge > maxX ? maxX : year;

  const mileage = quadraticY(year) - 0.17;

  return {
    id: "line-gradient",
    afterDatasetsDraw(chart: ChartJS<"line">) {
      const {
        ctx,
        chartArea: { top, bottom },
        scales: { x, y },
      } = chart;

      const xCoor = x.getPixelForValue(year);
      const yCoor = y.getPixelForValue(mileage);
      const gradient = ctx.createLinearGradient(0, top, 0, bottom);

      gradient.addColorStop(year > 1 ? 0.6 : 0.85, "black");
      gradient.addColorStop(1, "white");

      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.strokeStyle = gradient;
      ctx.moveTo(xCoor, yCoor);
      ctx.lineTo(xCoor, bottom);
      ctx.stroke();
      ctx.closePath();
    },
  };
};

const createBorderGradient = (
  ctx: CanvasRenderingContext2D,
  area: ChartArea
) => {
  const colorStart = colors.blue;
  const colorMid = colors.orange;
  const colorEnd = colors.red;

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
};

const createFillGradient = (
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  remainingDriveTrainWarrantyPercentage: number
) => {
  const colorStart = colors.blue;
  const colorMid = colors.orange;
  const colorEnd = colors.red;
  let midStop = 0.65;

  if (remainingDriveTrainWarrantyPercentage > 0) {
    midStop = remainingDriveTrainWarrantyPercentage / 100;
  }

  const gradient = ctx.createLinearGradient(0, 0, area.right, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(midStop, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
};

const createFillMaskGradient = (
  ctx: CanvasRenderingContext2D,
  area: ChartArea
) => {
  const colorStart = "rgba(255, 255, 255, 1)";
  const colorEnd = "rgba(255, 255, 255, 0)";

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.75, colorEnd);

  return gradient;
};

type Props = {
  vehicleWarranty: ExtendedWarrantyDefaultRatesRemainingWarranty;
  redraw?: boolean;
};

const WarrantyGraphChart = ({ vehicleWarranty, redraw = false }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation(["common", "purchase"]);
  const chartRef = useRef<ChartJS<"line">>(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  });

  const chartOptions = useMemo(() => {
    if (!vehicleWarranty) return {};

    const options: ChartOptions<"line"> = {
      aspectRatio: 3,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          afterTickToLabelConversion: ({ ticks, chart }) => {
            const oddTicks = ticks.filter((ti) => ti.value % 2);
            const lastTick = oddTicks.pop();
            // eslint-disable-next-line no-param-reassign
            chart.scales.x.ticks = [
              ...oddTicks,
              { ...lastTick, label: `+${lastTick?.label}` } as Tick,
            ];
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 5.1, // care at tweaking, make sure dot doesn't get cut off on mobile
          position: "right",
          display: true,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          title: {
            display: true,
            text: t("purchase:average_kms"),
          },
        },
      },
      responsive: true,
      elements: {
        line: {
          tension: 0.4,
        },
      },
    };

    const logo = new Image(34, 32);
    logo.src = DotIcon.src;

    const { vehicleAge } = vehicleWarranty;

    let x = vehicleAge < minX ? minX : vehicleAge;
    x = vehicleAge > maxX ? maxX : x;

    const lineAnnotationOptions = {
      ...lineAnnotation,
      xMax: x,
      xMin: x,
      yMax: quadraticY(x) - 0.15,
      yMin: 0,
      display: x > 0,
    };

    const labelAnnotationOptions = {
      ...labelAnnotation,
      content: logo,
      xValue: x,
      yValue: quadraticY(x),
    };

    const plugins = {
      ...options.plugins,
      scales: {
        ...options.scales,
        y: {
          title: {
            text: t("purchase:average_kms"),
          },
        },
      },
      annotation: {
        annotations: [lineAnnotationOptions, labelAnnotationOptions],
      },
    };

    return { ...options, plugins, locale };
  }, [locale, t, vehicleWarranty]);

  const chartPlugins = useMemo(() => {
    if (!vehicleWarranty) return [];

    return [lineGradient(vehicleWarranty)];
  }, [vehicleWarranty]);

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) return;
    if (!vehicleWarranty) return;

    const [maskDataset, bgDataset] = data.datasets;

    const cd = {
      ...data,
      labels: labels.map((label) => {
        let yearLabel = t("common:year");
        if (label > 1) {
          yearLabel = t("common:years");
        }

        return `${label} ${yearLabel}`;
      }),
      datasets: [
        {
          ...maskDataset,
          backgroundColor: createFillMaskGradient(chart.ctx, chart.chartArea),
          borderColor: createBorderGradient(chart.ctx, chart.chartArea),
        },
        {
          ...bgDataset,
          backgroundColor: createFillGradient(
            chart.ctx,
            chart.chartArea,
            vehicleWarranty.remainingDriveTrainWarrantyPercentage
          ),
          borderColor: createBorderGradient(chart.ctx, chart.chartArea),
        },
      ],
    };

    setChartData(cd);
    chartRef.current.update();
  }, [t, vehicleWarranty]);

  return (
    <>
      <Chart
        ref={chartRef}
        type="line"
        data={chartData}
        options={chartOptions}
        plugins={chartPlugins}
        redraw={redraw}
      />
      <div className="flex flex-col items-center justify-center pt-12">
        {/*
      Need to hard code the colors here.
      Tailwind doesn't seem to recognize variables.
    */}
        <div className="w-1/4 h-2 bg-gradient-to-r from-[#00CFEB] via-[#FCB180] to-[#F05983]" />
        <div className="h-2 border-primary-deep border-l-2" />
        <p className="caption-1 pt-2 text-primary-deep">
          {t("purchase:likelihood_of_needing_repairs")}
        </p>
      </div>
    </>
  );
};

export default WarrantyGraphChart;
