import { Instant, convert } from "@js-joda/core";

export type DateTimeFormat =
  | "MMMM D, YYYY"
  | "MM/dd/yyyy"
  | "MM/dd/yyyy hh:mm tt"
  | "MMM D, YYYY, HH:mm:ss"
  | "MMM D, YYYY, hh:mm:ss tt"
  | "MMM D, YYYY"
  | "MMMM, YYYY"
  | "h:mm aa";

type TDateTimeFormat = {
  [F in DateTimeFormat]: Intl.DateTimeFormatOptions;
};

export const getHoursFormatted = (time: string, locale: string) => {
  const parts = time.split(":") as unknown as number[];
  if (parts.length < 2) throw Error("Invalid time format");

  const date = new Date();
  date.setHours(parts[0], parts[1], 0, 0);

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat(locale, timeFormat).format(date);
};

export const getDateFormatted = (date: string, locale: string) => {
  const objDate = new Date(date);
  if (objDate.toString() === "Invalid Date") throw Error("Invalid date format");

  const dayFormat: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return new Intl.DateTimeFormat(locale, dayFormat).format(objDate);
};

export const DateTimeFormatOptions: TDateTimeFormat = {
  "MMMM D, YYYY": {
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  "MM/dd/yyyy": {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  "MM/dd/yyyy hh:mm tt": {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  },
  "MMM D, YYYY, HH:mm:ss": {
    dateStyle: "medium",
    timeStyle: "medium",
    hour12: false,
  },
  "MMM D, YYYY, hh:mm:ss tt": {
    dateStyle: "medium",
    timeStyle: "short",
    hour12: true,
  },
  "MMM D, YYYY": {
    dateStyle: "medium",
  },
  "MMMM, YYYY": {
    year: "numeric",
    month: "long",
  },
  "h:mm aa": {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  },
};

export const getFormattedInstant = (instant: Instant) => {
  const date = convert(instant).toDate();
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
};

export const dateTimeFormatter = (
  date: Date | null,
  locale: string,
  format: DateTimeFormat
) => {
  if (!date) return "";

  return new Intl.DateTimeFormat(locale, DateTimeFormatOptions[format]).format(
    date
  );
};

export const formatTimeOnly = (date: Date, locale: string) => {
  return date.toLocaleString(locale, {
    hour: "numeric",
    minute: "numeric",
    hour12: locale === "en-CA" || locale === "en",
  });
};

export default {};
