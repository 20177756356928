import Image, { ImageProps } from "next/image";
import { ReactNode, useState } from "react";
import purchaseSourceVehicleImagesLoader from "../helpers/purchaseSourceVehicleImagesLoader";

type Props = Omit<ImageProps, "loader"> & {
  fallback?: ReactNode;
  onError?: () => void;
};

const PurchaseSourceVehicleImage = ({
  src,
  fallback,
  onError,
  ...props
}: Props) => {
  const [hasError, setHasError] = useState(false);
  return !hasError ? (
    // eslint-disable-next-line react/forbid-elements
    <Image
      src={src}
      width={0}
      height={0}
      sizes="100vw"
      unoptimized
      loader={purchaseSourceVehicleImagesLoader}
      onError={() => {
        setHasError(true);
        onError?.();
      }}
      {...props}
    />
  ) : (
    <div>{fallback}</div>
  );
};

export default PurchaseSourceVehicleImage;
