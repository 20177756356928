import api from "@modules/api/api";
import { useQuery } from "@tanstack/react-query";
import { provinces } from "@common/types/Province";
import purchaseQueryKeys from "../purchaseQueryKeys";

export type GetResponseProvince = {
  id: number;
  abbr: string;
  isAftermarketEnabled: boolean;
  isIcoEnabled: boolean;
  isAppraisalEnabled: boolean;
};

type GetResponse = {
  provinces: GetResponseProvince[];
};

const performGet = async (
  dealSource?: string | undefined,
  resultFilter?: (value: GetResponseProvince) => boolean
) => {
  const resp = await api.get<GetResponse>(
    `api/purchases/eligible-provinces${
      dealSource ? `?dealSource=${dealSource}` : ""
    }`
  );
  const provinceIds = resp.provinces
    .filter(resultFilter ?? (() => true))
    .map((p) => p.id);
  return provinces.filter((p) => provinceIds.includes(p.id));
};

export const provinceFilters = {
  isAftermarketEnabled: (value: GetResponseProvince) =>
    value.isAftermarketEnabled,
  isIcoEnabled: (value: GetResponseProvince) => value.isIcoEnabled,
  isAppraisalEnabled: (value: GetResponseProvince) => value.isAppraisalEnabled,
};

export default function useEligibleProvinces(
  dealSource?: string,
  resultFilter?: (value: GetResponseProvince) => boolean
) {
  const { data, isLoading } = useQuery({
    queryKey: purchaseQueryKeys.eligibleProvinces(dealSource),
    queryFn: () => performGet(dealSource, resultFilter),
  });

  return { provinces: data ?? [], isProvincesLoading: isLoading };
}
