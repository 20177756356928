export const MAX_PIXEL_WIDTH = "10000";
export const MAX_PIXEL_HEIGHT = "10000";
export const MAX_DECIMALS_TAX_BREAKDOWN = 3;
export const VEHICLE_PRICE_MIN_DIFFERENCE_THRESHOLD = 500;
export const VEHILCE_PRICE_MAX_DIFFERENCE_PERCENTAGE_THRESHOLD = 0.2;
export const PROTECTION_RIGHTRIDE_CA_EMAIL = "protection@rightride.ca";
export const ICO_INQUIRY_EMAIL = "cashoffers@rightride.ca";
export const EW_ADDITIONAL_VEHICLE_COMPONENTS = "additionalVehicleComponents";
export const WARRANTY_LINK_BACK_UTM_SOURCE = "warranty-quote-link-back-email";
export const CERTIFICATE_OF_INSPECTION_URL =
  "https://www.google.com/search?q=example+of+certificate+of+inspection+in+my+province";
export const HOW_TO_ALLOW_POPUPS_URL =
  "https://www.google.com/search?q=how+to+enable+popup+on+this+device";
export const CONDITIONALLY_SUCCESSFUL_CHROME_DECODE = "ConditionallySuccessful";
export const WARRANTY_DISCOUNT_CAMPAIGN = "warranty-discount";
export const NEW_BUYER_PROMO_CODE = "NEWBUYER150";

export default {};
