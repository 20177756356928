import { useNumberMask } from "@common/hooks/useMask";
import { ExtendedWarrantyDefaultRatesRemainingWarranty } from "@modules/purchase/types/ExtendedWarrantyRates";
import classNames from "classnames";
import { useTranslation, Trans } from "react-i18next";
import { TranslationKeys } from "src/@types/i18next";

type RemainingWarrantyProps = {
  vehicleWarranty: ExtendedWarrantyDefaultRatesRemainingWarranty;
  headingClassName?: string;
  bodyClassName?: string;
  isModal?: boolean;
};

const RemainingWarranty = ({
  vehicleWarranty: {
    manufacturerWarrantyMaxMileageRemaining,
    warrantyMonthsRemaining,
    remainingDriveTrainWarrantyPercentage,
  },
  headingClassName,
  bodyClassName,
  isModal,
}: RemainingWarrantyProps) => {
  const { t } = useTranslation(["common", "purchase"]);
  const { maskPipe: maskNumberPipe } = useNumberMask();

  let header = t("purchase:your_manufacturer_warranty_is_running_low");
  let i18nKey: TranslationKeys["purchase"] =
    "purchase:while_having_existing_warranty_coverage";

  if (remainingDriveTrainWarrantyPercentage >= 50) {
    header = t("purchase:your_manufacturer_warranty_is_in_good_shape");
    i18nKey = "purchase:looks_like_remaining_vehicle_warranty";
  } else if (remainingDriveTrainWarrantyPercentage === 0) {
    header = t("purchase:your_manufacturer_warranty_has_expired");
    i18nKey = "purchase:dont_worry_we_have_warranty_options_available";
  }

  return (
    <>
      <h3
        className={classNames(
          "text-text-light-100 lg:tracking-tight",
          {
            "text-2xl lg:text-4xl": !isModal,
            "text-xl": isModal,
          },
          headingClassName
        )}
      >
        {header}
      </h3>
      <h6
        className={classNames(
          "text-gray-600 pt-4",
          {
            "lg:text-2xl": !isModal,
            "text-base": isModal,
          },
          bodyClassName
        )}
      >
        <Trans
          t={t}
          i18nKey={i18nKey}
          values={{
            remainingMonths: warrantyMonthsRemaining,
            remainingMileage: maskNumberPipe(
              manufacturerWarrantyMaxMileageRemaining.toString()
            ),
          }}
          components={{
            b: <span className="font-semibold" />,
            bblue: <span className="font-semibold text-primary-bold" />,
            brblue: <span className="font-semibold text-primary-bright" />,
          }}
        />
      </h6>
    </>
  );
};

export default RemainingWarranty;
