import { useRouter } from "next/router";
import objectUtils from "@common/helpers/objectUtils";
import useCreateCustomerInformation from "./useCreateCustomerInformation";
import { BuyerQueryParams } from "../types";

export const useRawBuyerInfoFromQuery = () => {
  const router = useRouter();

  const {
    buyerFirst,
    buyerLast,
    buyerAddress,
    buyerCity,
    buyerProvince,
    buyerPostal,
    buyerPhoneNumber,
    buyerEmail,
  } = router.query as BuyerQueryParams;

  return objectUtils.mapValidProperties({
    buyerFirst,
    buyerLast,
    buyerAddress,
    buyerCity,
    buyerProvince,
    buyerPostal,
    buyerPhoneNumber,
    buyerEmail,
  }) as BuyerQueryParams;
};

const useBuyerInfoFromQuery = () => {
  const {
    buyerFirst: firstName,
    buyerLast: lastName,
    buyerAddress: streetAddress,
    buyerCity: city,
    buyerProvince: provinceAbbr,
    buyerPostal: postalCode,
    buyerPhoneNumber: phoneNumber,
    buyerEmail: email,
  } = useRawBuyerInfoFromQuery();

  const buyerInformation = useCreateCustomerInformation({
    firstName,
    lastName,
    streetAddress,
    city,
    provinceAbbr,
    postalCode,
    phoneNumber,
    email,
  });

  return buyerInformation;
};

export default useBuyerInfoFromQuery;
