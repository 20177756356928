import FSAGeographicArea from "@common/types/FSAGeographicArea";
import { Province } from "@common/types/Province";
import { convert, LocalDate } from "@js-joda/core";

const firstName = {
  maxLength: 50,
  valid: (value: string) => {
    if (!value) return false;
    if (value.length > firstName.maxLength) return false;

    return true;
  },
};

const lastName = {
  maxLength: 50,
  valid: (value: string) => {
    if (!value) return false;
    if (value.length > lastName.maxLength) return false;

    return true;
  },
};

const streetAddress = {
  maxLength: 254,
};

const city = {
  maxLength: 50,
};

const postalCode = {
  pattern:
    /**
     * Canadian postal code regex:
     *
     * Optional space in between 3 characters
     *
     * Disallows:
     * - Leading Z or W
     * - D, F, I, O, Q, U
     */
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/,
  maxLength: 7,
  validateWithProvince: (
    value: string | null,
    province: Province | null | undefined
  ) => {
    if (!value || value === "" || !province) {
      return true;
    }

    return province.fsaAreas.includes(value[0] as FSAGeographicArea);
  },
};

const email = {
  required: true,
  maxLength: 254,
  pattern: /^[\w._+-]+@[\w._]+\.\w+$/,
  valid: (value: string) => {
    if (!value) return false;
    if (value.length > email.maxLength) return false;

    return email.pattern.test(value);
  },
};

const phoneNumber = {
  maxLength: 16,
  valueLength: 10,
  valid: (value: string) => {
    if (!value) return false;
    if (value.length > phoneNumber.valueLength) return false;

    return value.length === phoneNumber.valueLength;
  },
  validAreaCode: (value: string) => {
    if (!value) return false;

    const [areaCode] = value.split("");

    return +areaCode > 1;
  },
};

const defaultMinAge = 18;
const defaultMaxAge = 120;

const dateOfBirth = {
  minAge: defaultMinAge,
  minAgeToBuyCoverage: 16,
  maxAge: defaultMaxAge,
  getMinLocalDate: (today: LocalDate, maxAge = defaultMaxAge) =>
    today.minusYears(maxAge),
  getMaxLocalDate: (today: LocalDate, minAge = defaultMinAge) =>
    today.minusYears(minAge),
  getMinDate: (today: LocalDate, maxAge = defaultMaxAge) =>
    convert(dateOfBirth.getMinLocalDate(today, maxAge)).toDate(),
  getMaxDate: (today: LocalDate, minAge = defaultMinAge) =>
    convert(dateOfBirth.getMaxLocalDate(today, minAge)).toDate(),
};

const customerValidation = {
  firstName,
  lastName,
  streetAddress,
  city,
  postalCode,
  email,
  phoneNumber,
  dateOfBirth,
};
/*
  To be replaced with customerValidationNew.ts after all components are migrated
  to use the new validation objects.
*/
export default customerValidation;
