import * as Icons from "@common/components/icons/brandsIndex";
import * as BodyStyleIcons from "@common/components/icons/bodyStylesIndex";
import { VehicleSedanIcon } from "@common/components/icons";

type IconsType = typeof Icons;
type BodyStyleIconsType = typeof BodyStyleIcons;

const normalizeMake = (make: string) => {
  const words = make.indexOf("-") > -1 ? make.split("-") : make.split(" ");

  return words
    .map((w) => {
      return `${w.charAt(0).toUpperCase()}${w.slice(1).toLowerCase()}`;
    })
    .join("");
};

export function getBodyStyleIcon(bodyStyle: string) {
  const bodyStyleKey = `Vehicle${bodyStyle}Icon` as keyof BodyStyleIconsType;
  const BodyStyleIcon = BodyStyleIcons[bodyStyleKey] || VehicleSedanIcon;

  return BodyStyleIcon;
}

export default function getMakeIcon(make: string) {
  const sanitizedMake = normalizeMake(make);
  const brandKey = `Brands${sanitizedMake}Icon` as keyof IconsType;
  const BrandIcon = Icons[brandKey] || VehicleSedanIcon;

  return BrandIcon;
}
