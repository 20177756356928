import { useMemo } from "react";
import { getProvinceByAbbr } from "@common/types/Province";
import { usePhoneNumberMask, usePostalCodeMask } from "@common/hooks/useMask";
import customerValidation from "@common/helpers/validations/customerValidationDeprecated";
import { CustomerInformation } from "../types";

type CustomerInfo = {
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  city?: string;
  provinceAbbr?: string;
  postalCode?: string;
  email?: string;
  phoneNumber?: string;
};

const parsePostalCode = (
  value: string | undefined,
  maskPipe: (value: string) => string
) => {
  if (
    !value ||
    value === "" ||
    value.length > customerValidation.postalCode.maxLength - 1
  ) {
    return null;
  }

  const maskedPostalCode = maskPipe(value);

  return maskedPostalCode.length === customerValidation.postalCode.maxLength
    ? maskedPostalCode
    : null;
};

const parseProvince = (abbr: string | undefined) => {
  if (!abbr || abbr === "") {
    return null;
  }

  const province = getProvinceByAbbr(abbr);

  return province ?? null;
};

const parsePhoneNumber = (
  phone: string | undefined,
  maskPipe: (value: string) => string
) => {
  const pattern = /^\d{10}$/;

  if (!phone || phone === "" || !pattern.test(phone)) {
    return null;
  }

  return maskPipe(phone);
};

const useCreateCustomerInformation = ({
  firstName,
  lastName,
  streetAddress,
  city,
  provinceAbbr,
  postalCode,
  email,
  phoneNumber,
}: CustomerInfo) => {
  const { maskPipe: maskPostalCodePipe } = usePostalCodeMask();
  const { maskPipe: maskPhoneNumberPipe } = usePhoneNumberMask();

  const postalDisplayValue = parsePostalCode(postalCode, maskPostalCodePipe);
  const province = useMemo(() => parseProvince(provinceAbbr), [provinceAbbr]);
  const phoneDisplayValue = parsePhoneNumber(phoneNumber, maskPhoneNumberPipe);

  const fieldsInput: CustomerInformation = useMemo(() => {
    return {
      firstName:
        firstName && firstName.length <= customerValidation.firstName.maxLength
          ? firstName
          : null,
      lastName:
        lastName && lastName.length <= customerValidation.lastName.maxLength
          ? lastName
          : null,
      streetAddress:
        streetAddress &&
        streetAddress.length <= customerValidation.streetAddress.maxLength
          ? streetAddress
          : null,
      city:
        city && city.length <= customerValidation.city.maxLength ? city : null,
      postalCode: postalDisplayValue,
      province,
      email:
        email && customerValidation.email.pattern.test(email) ? email : null,
      phoneNumber: phoneDisplayValue,
    };
  }, [
    city,
    email,
    firstName,
    lastName,
    phoneDisplayValue,
    postalDisplayValue,
    province,
    streetAddress,
  ]);

  return fieldsInput;
};

export default useCreateCustomerInformation;
